<template>
    <div class="login">
        <form class="login__form box box_bg-gray" @submit.prevent="handleSubmit">
            <h1 class="login__title">Авторизация</h1>
            <p class="login__error" v-if="error">{{error}}</p>
            <div class="login__field login__field_username">
                <label class="login__label" for="login-username">Логин</label>
                <input class="login__input"
                    id="login-username"
                    name="login-username"
                    type="text"
                    autocomplete="username"
                    v-model="username"
                />
            </div>
            <div class="login__field login__field_password">
                <label class="login__label" for="login-password">Пароль</label>
                <input class="login__input"
                    id="login-password"
                    name="login-password"
                    autocomplete="current-password"
                    type="password"
                    v-model="password"
                />
            </div>
            <button class="login__submit" :disabled="disabled">Войти</button>
        </form>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        name: "Login",
        data() {
            return {
                username: "",
                password: "",
                error: "",
                disabled: false
            }
        },
        methods: {
            ...mapActions(["login"]),
            handleSubmit() {
                this.disabled = true;

                this.login({
                    username: this.username,
                    password: this.password
                }).then(() => {
                    this.$router.push("/");
                }).catch (error => {
                    this.error = error.response.data.error;
                    this.disabled = false;
                })
            }
        }
    }
</script>

<style lang="scss">
    .login {
        min-height: 100vh;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        padding: 10px 8px;
        background-color: $background-gray-primary;
    }
    .login__error {
        color: red;
        margin-bottom: 10px;
    }
    .login__form {
        width: 330px;
        padding: 24px 32px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;
    }
    .login__title {
        line-height: 1;
        text-align: center;
        margin-bottom: 15px;
    }
    .login__field {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;

        & + & {
            margin-top: 10px;
        }
    }
    .login__label {
        margin-bottom: 5px;
    }
    .login__input {
        padding: 8px;
        border: 1px solid $gray-line;
        border-radius: 6px;
    }
    .login__submit {
        margin-top: 30px;
        padding: 12px;
        color: #fff;
        background-color: $red-secondary;
        border-radius: 6px;

        &:hover {
            background-color: $red-primary;
        }

        &[disabled] {
            background-color: $gray-form;
        }
    }
</style>
